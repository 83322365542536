function parseHierarchicalJSON(json, prefix) {
    let output = {};

    Object.keys(json).forEach(prop => {
        const currentKey = (prefix || '') + prop;

        if (typeof json[prop] === 'string' || Array.isArray(json[prop])) {
            output[currentKey] = json[prop];
        } else {
            const data = parseHierarchicalJSON(json[prop], `${currentKey}.`);
            output = Object.assign(output, data);
        }
    });

    return output;
}

module.exports = (json, locale) => ({
    data: parseHierarchicalJSON(json),
    locale
});
